// external
import React, { useState } from 'react';
import { Box, Button, Card, Fade, SxProps, Theme } from '@mui/material';
import { useParams } from 'react-router-dom';

// internal
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';

// components
import { GuideRegistrationPreview } from 'components';

// store

import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import { ProfilePreviewProfileData } from 'components/ProfilePreview';
import { IMembershipForm } from 'store/slices/formsSlice';

interface GuideRegistrationCardProps {
  guide: ProfilePreviewProfileData;
  data: IMembershipForm;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps<Theme>;
  isBestMatch?: boolean;
  action?: () => void;
  blurred?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const GuideRegistrationCard: React.FC<GuideRegistrationCardProps> = ({
  disabled = false,
  guide,
  data,
  sx = {},
  isBestMatch,
  action,
  blurred,
  isLoading,
}) => {
  const [isBlurred, setIsBlurred] = useState(blurred);
  const isMobile = useMobileMediaQuery();
  const { trackMixpanelEvent } = useMixpanelEvents();

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(programSlug);

  if (!program || !role) {
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual;

  const revealProfile = () => {
    setIsBlurred(false);
  };

  const handleRevealProfileButtonClick = () => {
    revealProfile();
    trackMixpanelEvent('Trainee Program Registration - Reveal guide profile', {
      'Program type': programType?.program_type_text.common?.verb,
    });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'auto',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'stretch',
        minHeight: '300px',
        position: 'relative',
        ...sx,
      }}
    >
      <Fade in={isBlurred} appear={false}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, .5)',
            backdropFilter: 'blur(8px)',
            zIndex: 1,
            pb: '10%',
          }}
        >
          <Button
            data-cy="components_Guide_GuideCard_guide-card-button"
            variant="contained"
            color="info"
            onClick={handleRevealProfileButtonClick}
          >
            {
              programVariation?.registration?.registration_trainee
                ?.registration_trainee_choose_a_guide
                ?.registration_trainee_choose_a_guide_reveal_profile_button_label
            }
          </Button>
        </Box>
      </Fade>
      <GuideRegistrationPreview
        programSlug={programSlug}
        profileData={guide}
        membershipData={data}
      />
    </Card>
  );
};
